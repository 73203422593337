.rtl-support{
  .phone-input {
    direction: ltr;
    text-align: right;
  }
}

.input-without-border-radius {
  border-radius: unset;
  margin-left: -1px;
}

#input-without-border-radius-password {
  margin-left: -1px;
  width: 50%;
}
